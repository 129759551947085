import Partner1Image from '../../Assets/Image/Aps/partners/partner-1.png';
import Partner2Image from '../../Assets/Image/Aps/partners/partner-2.png';
import Partner3Image from '../../Assets/Image/Aps/partners/partner-3.png';
import Partner4Image from '../../Assets/Image/Aps/partners/partner-4.png';
import Partner5Image from '../../Assets/Image/Aps/partners/partner-5.png';
import Partner6Image from '../../Assets/Image/Aps/partners/partner-6.png';
import Partner7Image from '../../Assets/Image/Aps/partners/partner-7.png';
import Partner8Image from '../../Assets/Image/Aps/partners/partner-8.png';
import Partner9Image from '../../Assets/Image/Aps/partners/partner-9.png';
import Partner10Image from '../../Assets/Image/Aps/partners/partner-10.png';
import Partner11Image from '../../Assets/Image/Aps/partners/partner-11.png';
import Partner12Image from '../../Assets/Image/Aps/partners/partner-12.png';
import Partner13Image from '../../Assets/Image/Aps/partners/partner-13.png';
import Partner14Image from '../../Assets/Image/Aps/partners/partner-14.png';
import { useTranslation } from 'react-i18next';

function Partners({ withHeader }) {
    const { t } = useTranslation();

    return (
        <div className="container py-16">
            {withHeader && <h1 className="text-center text-3xl mb-5 !not-italic">{t('aps.partners.title')}</h1>}
            <div className="flex gap-x-1 gap-y-4 [&>img]:h-[40px] md:[&>img]:h-[55px] lg:[&>img]:h-[60px] flex-wrap justify-center">
                <img src={Partner1Image} alt="partner-1" />
                <img src={Partner2Image} alt="partner-2" />
                <img src={Partner3Image} alt="partner-3" />
                <img src={Partner4Image} alt="partner-4" />
                <img src={Partner5Image} alt="partner-5" />
                <img src={Partner6Image} alt="partner-6" />
                <img src={Partner7Image} alt="partner-7" />
                <img src={Partner8Image} alt="partner-8" />
                <img src={Partner9Image} alt="partner-9" />
                <img src={Partner10Image} alt="partner-10" />
                <img src={Partner11Image} alt="partner-11" />
                <img src={Partner12Image} alt="partner-12" />
                <img src={Partner13Image} alt="partner-13" />
                <img src={Partner14Image} alt="partner-14" />
            </div>
        </div>
    );
}

export default Partners;