import { useContext, useEffect, useState } from "react";
import Layout from "../../Layouts";
import AdminContext from "../../Services";
import ArrowRightIcon from "../../Assets/Icon/arrow-right.svg";
import ArrowLeftIcon from "../../Assets/Icon/arrow-left.svg";
import NewsCard from "./Components/Card";
import { t } from "i18next";

function NewsPage() {
  const { loading, getAllMaster } = useContext(AdminContext);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 1,
    page: 1,
  });

  const getData = async () => {
    const newData = await getAllMaster("public/news?page=" + pagination?.page);
    if (newData) {
      setData(newData?.data);
      setPagination({
        ...pagination,
        total: Math.ceil(newData?.total ?? 0 / 12),
      });
    }
  };

  const onClick = (type) => {
    let page = pagination?.page;
    if (type === "-") {
      if (page > 1) {
        page--;
      }
    } else {
      if (page < pagination?.total) {
        page++;
      }
    }
    setPagination({ ...pagination, page: page });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getData();
  }, [pagination?.page]);

  return (
    <Layout>
      <main id="main" className="about-us">
        <div className="breadcrumbs bg-news">
          <div className="container">
            <h1>{t("news_title").toUpperCase()}</h1>
          </div>
        </div>
        <section className="container">
          <div className="row align-items-center justify-content-center">
            {data?.map((item, index) => (
              <NewsCard item={item} key={index}/>
            ))}
          </div>
          <div className="pagination">
            <img src={ArrowLeftIcon} alt="" onClick={() => onClick("-")} />
            <div>
              {pagination?.page}/{pagination?.total}
            </div>
            <img src={ArrowRightIcon} alt="" onClick={() => onClick("+")} />
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default NewsPage;
