import Partner1Image from "../Assets/Image/Home/partner-1.png";
import Partner2Image from "../Assets/Image/Home/partner-2.png";
import Partner3Image from "../Assets/Image/Home/partner-3.png";
import Partner4Image from "../Assets/Image/Home/partner-4.png";
import Partner5Image from "../Assets/Image/Home/partner-5.png";
import Partner6Image from "../Assets/Image/Home/partner-6.png";
import Partner7Image from "../Assets/Image/Home/partner-7.png";
import Partner8Image from "../Assets/Image/Home/partner-8.png";
import Partner9Image from "../Assets/Image/Home/partner-9.png";
import Partner10Image from "../Assets/Image/Home/partner-10.png";
import Partner11Image from "../Assets/Image/Home/partner-11.png";
import Partner12Image from "../Assets/Image/Home/partner-12.png";
import Partner13Image from "../Assets/Image/Home/partner-13.png";
import Partner14Image from "../Assets/Image/Home/partner-14.png";
import { useEffect } from "react";

function Partners(params) {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="row align-items-center justify-content-center">
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner1Image} className="img-fluid" alt="partner-1" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner2Image} className="img-fluid" alt="partner-2" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner3Image} className="img-fluid" alt="partner-3" />
      </div>
      <div className="mb-3 col-6 col-md-3">
        <img src={Partner4Image} className="img-fluid" alt="partner-4" />
      </div>
      <div className="mb-3 col-6 col-md-3">
        <img src={Partner5Image} className="img-fluid" alt="partner-5" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner6Image} className="img-fluid" alt="partner-6" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner7Image} className="img-fluid" alt="partner-7" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner8Image} className="img-fluid" alt="partner-8" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner9Image} className="img-fluid" alt="partner-9" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner10Image} className="img-fluid" alt="partner-10" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner11Image} className="img-fluid" alt="partner-11" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner12Image} className="img-fluid" alt="partner-12" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner13Image} className="img-fluid" alt="partner-13" />
      </div>
      <div className="mb-3 col-6 col-md-2">
        <img src={Partner14Image} className="img-fluid" alt="partner-14" />
      </div>
    </div>
  );
}

export default Partners;
