import { useTranslation } from 'react-i18next';

function CompactFooter() {
    const { t } = useTranslation();

    return (
        <div className="container flex flex-col items-center py-10">
            <div className="md:max-w-[50%] space-y-5">
            <div className="flex flex-col items-center">
                <h3 className="text-blue-800 font-bold">{t('aps.footer.contact_title')}</h3>
                <span className="text-base md:text-xl !font-mono font-bold">+62 21-270 982 44</span>
                <a href="mailto:info@thepasgroup.id" className="text-base md:text-xl !font-mono font-bold">info@thepasgroup.co.id</a>
            </div>

            <div className="flex flex-col items-center">
                <h3 className="text-blue-800 font-bold">{t('aps.footer.address_title')}</h3>
                <h4 className="text-base md:text-lg font-bold text-blue-900">Head Office of Cilegon</h4>
                <p className="text-center text-sm md:text-base text-slate-600">
                    Jl. Komplek Arga Baja Pura, Jalan Arga Raung Blok B3 No 31 Kel. Grogol
                    Kec. Grogol, Cilegon, Banten. 42436
                </p>
            </div>
            </div>
        </div>
    );
}

export default CompactFooter;