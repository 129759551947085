import { useCallback, useEffect, useState, useRef } from 'react';
import CrushedStonesImage from '../../Assets/Image/Pasniaga/crushed-stones.jpg';
import CoalImage from '../../Assets/Image/Pasniaga/coal.jpg';
import SandImage from '../../Assets/Image/Pasniaga/sand.jpg';
import CementImage from '../../Assets/Image/Pasniaga/cement.jpg';
import ContactUsImage from '../../Assets/Image/Pasniaga/contact-us.jpg';
import About1Image from '../../Assets/Image/Pasniaga/about-1.jpg';
import About2Image from '../../Assets/Image/Pasniaga/about-2.jpg';
import About3Image from '../../Assets/Image/Pasniaga/about-3.jpg';
import BannerImage from '../../Assets/Image/Pasniaga/banner.jpg';

import LanguageIcon from "../../Assets/Icon/language.png";
import IndonesiaIcon from "../../Assets/Icon/indonesia.svg";
import EnglishIcon from "../../Assets/Icon/english.svg";
import JapanIcon from "../../Assets/Icon/japan.svg";
import ChinaIcon from "../../Assets/Icon/china.svg";
import { Trans, useTranslation } from 'react-i18next';

import CancelIcon from '../../Assets/Image/cancel.svg';
import HamburgerIcon from '../../Assets/Image/hamburger.svg';
import { t } from 'i18next';

function Pasniaga() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        import('../../tailwind.css')
            .then(() => {
                setLoading(false);
            })
            .catch(() => { });
    }, []);

    if (loading) {
        return (
            <div style={
                {
                    minWidth: "100vw",
                    minHeight: "100vh",
                    background: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }
            }>
                LOADING
            </div>
        );
    }

    return (
        <div>
            <Navbar />
            <Banner />
            <AboutUs />
            <OurServices />
            <ContactUs />
            <Footer />
        </div>
    );
}

function Navbar() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [navBackground, setNavBackground] = useState(false);

    const scrollTo = useCallback((section, isTop = false) => {
        if (isTop) {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        }
        
        window.location.hash = section;
        setOpen(false);
    }, []);

    useEffect(() => {
        const calculateBackground = () => {
            if (window.scrollY > 50) {
                setNavBackground(true);
            } else {
                setNavBackground(false);
            }
        };

        window.addEventListener('scroll', calculateBackground);

        return () => {
            window.removeEventListener('scroll', calculateBackground);
        };
    }, []);

    return (
        <div className={`fixed top-0 left-0 w-full transition-all md:transition-none duration-200 z-50 ${open ? 'h-screen overlay' : ''} ${navBackground ? open ? 'md:bg-white shadow' : 'bg-white shadow' : ''}`}>
            <div className={`${open ? 'm-4 bg-white rounded p-4 min-h-[95vh]' : 'container'} md:mx-auto py-3 flex flex-col md:flex-row md:justify-between`}>
                <div className="flex justify-between">
                    <h1 className="!not-italic leading-none hover:cursor-pointer" onClick={() => scrollTo('home', true)}>
                        <span className="text-2xl font-bold text-corporate">PAS</span>
                        <span className="text-2xl font-bold text-green-600">NIAGA</span>
                    </h1>
                    <div className="md:hidden">
                        {
                            open
                                ? <button onClick={() => setOpen(false)}>
                                    <img src={CancelIcon} className="w-[24px] h-[24px]" />
                                </button>
                                : <div className="flex items-center gap-3">
                                    <LanguageSelect />
                                    <button onClick={() => setOpen(true)}>
                                        <img src={HamburgerIcon} className="w-[24px] h-[24px]" />
                                    </button>
                                </div>
                        }
                    </div>
                </div>
                <div>
                    <ul className={`${open ? 'inline-flex mt-4 font-bold' : 'hidden'} ${navBackground || open ? 'text-slate-700' : 'text-slate-100'} md:inline-flex flex-col md:flex-row gap-4 [&>li]:hover:cursor-pointer text-base`}>
                        <li className="hover:text-green-700" onClick={() => window.location.href = '/'}>{t('pn.navbar.home')}</li>
                        <li className="hover:text-green-700" onClick={() => scrollTo('about-us')}>{t('pn.navbar.about')}</li>
                        <li className="hover:text-green-700" onClick={() => scrollTo('contact-us')}>{t('pn.navbar.contact')}</li>
                        <li>
                            <LanguageSelect />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function LanguageSelect() {
    const [languageIcon, setLanguageIcon] = useState(EnglishIcon);
    const { i18n } = useTranslation();

    const handleLanguageChange = (langId, icon) => {
        i18n.changeLanguage(langId);
        setLanguageIcon(icon);
    };

    return (
        <div className="group relative">
            <div className="flex gap-2">
                <img src={LanguageIcon} alt="language-icon" className="h-6 w-6" />
                <img src={languageIcon} alt="language-icon" className="h-6 w-6" />
            </div>
            <div className="absolute transition-transform duration-100 delay-100 group-hover:block scale-0 group-hover:scale-100 pt-4">
                <ul className="space-y-4 bg-[rgba(255,255,255,0.5)] shadow p-2 rounded">
                    <li onClick={() => handleLanguageChange('id', IndonesiaIcon)}>
                        <img src={IndonesiaIcon} alt="language-icon" className="h-8 w-8 rounded-full" />
                    </li>
                    <li onClick={() => handleLanguageChange('en', EnglishIcon)}>
                        <img src={EnglishIcon} alt="language-icon" className="h-8 w-8 rounded-full" />
                    </li>
                </ul>
            </div>
        </div>
    );
}

function Banner() {
    const { t } = useTranslation();

    return (
        <div id="home" className="h-screen relative bg-blue-500">
            <img src={BannerImage} className="size-full object-cover" />
            <div className="absolute left-0 w-full top-0 h-full">
                <div className="h-full text-white mx-auto p-4 container">
                    <div className="md:max-w-[60%] lg:max-w-[80%] h-full flex flex-col justify-center gap-4">
                        <h2 className="!leading-tight text-3xl md:text-6xl font-bold">{t('pn.banner.greeting')}</h2>
                        <p className="text-base md:text-2xl font-light">
                            <Trans i18nKey="pn.banner.description" components={{ hightlight: <span className="text-green-400 text-base md:text-2xl font-bold" /> }} />
                        </p>
                        <div>
                            <button className="outline outline-2 outline-white px-4 py-2 text-sm md:text-base">
                                {t('pn.banner.learn')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function AboutUs() {
    const { t } = useTranslation();

    return (
        <div id="about-us" className="py-20 px-4 container">
            <div className="space-y-2">
                <h2 className="!leading-tight font-bold text-3xl md:text-5xl text-blue-950 md:max-w-[60%]">
                    {t('pn.about.title')}
                </h2>
                <div class="group relative inline-block pb-1.5 mb-2">
                    <h3 class="text-2xl md:text-3xl font-bold text-green-700">{t('pn.about.subtitle')}</h3>
                    <div class="absolute left-0 bottom-0 w-[40%] h-1.5 bg-green-700 origin-left transition-all duration-300 ease-out group-hover:w-[90%]"></div>
                </div>
                <p className="text-base font-light md:text-lg text-slate-600 lg:mr-10 max-w-[80%]">
                    {t('pn.about.description')}
                </p>
            </div>
            <div className="grid relative max-h-[250px] mt-4 gap-2 grid-cols-2 md:grid-cols-4">
                <img className="size-full object-cover" src={About1Image} />
                <img className="size-full object-cover" src={About2Image} />
                <img className="size-full object-cover" src={About3Image} />
                <img className="size-full object-cover" src={BannerImage} />
            </div>
        </div>
    );
}

function OurServices() {
    const { t } = useTranslation();

    return (
        <div id="our-services" className="py-20 px-4 container flex flex-col gap-2 space-y-5">
            <h2 className="text-center font-bold text-4xl md:text-5xl text-green-600">
                {t('pn.service.title')}
            </h2>
            <p className="text-center text-base md:text-lg text-slate-600">
                {t('pn.service.description')}
            </p>
            <div className="md:mx-9 lg:mx-16 mt-5 grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                <ServiceCard
                    imageSrc={CrushedStonesImage}
                    title={t('pn.service.1_title')}
                    description={t('pn.service.1_description')} />
                <ServiceCard
                    imageSrc={CoalImage}
                    title={t('pn.service.2_title')}
                    description={t('pn.service.2_description')} />
                <ServiceCard
                    imageSrc={CementImage}
                    title={t('pn.service.3_title')}
                    description={t('pn.service.3_description')} />
                <ServiceCard
                    imageSrc={SandImage}
                    title={t('pn.service.4_title')}
                    description={t('pn.service.4_description')} />
            </div>
        </div>
    );
}

function ContactUs() {
    const { t } = useTranslation();

    return (
        <div id="contact-us" className="relative my-10">
            <img src={ContactUsImage} className="h-[400px] w-full object-cover" />
            <div className="absolute top-0 left-0 size-full bg-gradient-to-r from-blue-900 to-green-800 opacity-70"></div>
            <div className="absolute top-0 left-0 size-full flex flex-col items-center justify-center text-white">
                <h2 className="font-bold text-3xl md:text-5xl">{t('pn.contact.title')}</h2>
                <p className="text-center font-light text-base md:text-2xl p-4 md:max-w-[70%]">
                    {t('pn.contact.description')}
                </p>
                <a href="mailto:trading@thepasgroup.co.id" className="text-sm md:text-2xl bg-corporate transition-colors duration-300 hover:bg-green-800 shadow-2xl px-4 py-2 hover:cursor-pointer rounded font-semibold">
                    {t('pn.contact.get_in_touch')}
                </a>
            </div>
        </div>
    );
}

function Footer() {
    return (
        <div className="mx-auto py-10 container">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                    <h1 className="!not-italic leading-none">
                        <span className="text-3xl md:text-5xl font-bold text-corporate">PAS</span>
                        <span className="text-3xl md:text-5xl font-bold text-green-600">NIAGA</span>
                    </h1>
                    <p className="text-slate-600 text-sm md:text-lg">
                        {/* {t('pn.footer.motto', { interpolation: {  } })} */}
                        <Trans i18nKey="pn.footer.motto" />
                    </p>
                </div>
                <div className="col-span-1 flex flex-col">
                    <h2 className="text-indigo-700 text-lg md:text-3xl font-bold mb-4">
                        {t('pn.footer.contact')}
                    </h2>
                    <span className="text-base md:text-2xl !font-mono font-bold">+62 21-270 982 44</span>
                    <a href="mailto:trading@thepasgroup.co.id" className="text-base md:text-2xl !font-mono font-bold">trading@thepasgroup.co.id</a>

                </div>
                <div className="flex flex-col gap-4 col-span-1">
                    <h3 className="font-bold text-3xl text-blue-800">{t('pn.footer.address')}</h3>
                    <div>
                        <h4 className="text-base md:text-xl font-bold text-blue-900">Head Office of Jakarta</h4>
                        <p className="text-sm md:text-lg text-slate-600">
                            Wisma Iskandarsyah Block A4-A5, Jl. Iskandarsyah Raya Kav.12-14, Melawai,
                            Kebayoran Baru, Jakarta Selatan. 12160
                        </p>
                    </div>
                    {/* <div>
                            <h4 className="text-base md:text-xl font-bold text-blue-900">Branch Office of Samarinda</h4>
                            <p className="text-sm md:text-lg text-slate-600">
                                Jl. H. Marhusin No.16, RT. 018, Sungai Kapih, Sambutan, Samarinda. 75115
                            </p>
                        </div>
                        <div>
                            <h4 className="text-base md:text-xl font-bold text-blue-900">Branch Office of Banten</h4>
                            <p className="text-sm md:text-lg text-slate-600">
                                Jl. Komplek Arga Baja Pura, Jalan Arga Raung Blok B3 No 31 Kel. Grogol
                                Kec. Grogol, Cilegon, Banten. 42436
                            </p>
                        </div>
                        <div>
                            <h4 className="text-base md:text-xl font-bold text-blue-900">Branch Office of Samarinda</h4>
                            <p className="text-sm md:text-lg text-slate-600">
                                Jl. H. Marhusin No.16, RT. 018, Sungai Kapih, Sambutan, Samarinda. 75115
                            </p>
                        </div>
                        <div>
                            <h4 className="text-base md:text-xl font-bold text-blue-900">Branch Office of Banten</h4>
                            <p className="text-sm md:text-lg text-slate-600">
                                Jl. Komplek Arga Baja Pura, Jalan Arga Raung Blok B3 No 31 Kel. Grogol
                                Kec. Grogol, Cilegon, Banten. 42436
                            </p>
                        </div> */}
                </div>
            </div>
        </div>
    );
}

function ServiceCard({ imageSrc, title, description }) {
    const imageRef = useRef();
    const [imageHeight, setImageHeight] = useState(null);

    useEffect(() => {
        if (!imageRef) return;

        setImageHeight(imageRef.current.width);
    }, [imageRef]);

    return (
        <div className="group shadow-sm w-full flex flex-col gap-2">
            <div style={{ height: imageHeight + 'px' }} className="md:h-[200px] overflow-hidden">
                <img ref={imageRef} src={imageSrc} className="mb-4 size-full object-cover transition-transform group-hover:scale-110 group-hover:transition-transform" />
            </div>
            <div class="relative inline-block mx-3 pt-4">
                <h3 class="text-lg font-bold group-hover:text-green-700 pb-1.5">{title}</h3>
                <div class="absolute left-0 bottom-0 w-[40%] h-1.5 bg-green-700 origin-left transition-all duration-300 delay-100 ease-out group-hover:w-[90%]"></div>
            </div>
            <p className="text-base md:text-lg font-light text-gray-600 mx-3 pb-4">
                {description}
            </p>
        </div>
    );
}

export default Pasniaga;