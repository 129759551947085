import Layout from "../../Layouts";
import Content1Image from "../../Assets/Image/AboutUs/content-1.png";
import Content2Image from "../../Assets/Image/AboutUs/content-2.png";
import Content3Image from "../../Assets/Image/AboutUs/content-3.png";
import Content4Image from "../../Assets/Image/AboutUs/content-4.png";
import Content5Image from "../../Assets/Image/AboutUs/content-5.png";
import TeamsImage from "../../Assets/Image/AboutUs/teams-4k.png";
import { useEffect } from "react";
import { t } from "i18next";

function AboutUsPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <Layout>
      <main id="main" className="about-us">
        <div className="breadcrumbs bg-about-us">
          <div className="container">
            <h1>{t("about_us_title").toUpperCase()}</h1>
          </div>
        </div>
        <section className="container">
          <h2 className="mb-3">{t("introduction")}</h2>
          <div>{t("about_us_description")}</div>
          <div>
            <img src={Content1Image} alt="content" className="img-fluid my-3" />
          </div>
          <div>{t("license")}</div>
        </section>
        <section className="container text-center commitment">
          <div className="row justify-content-center align-items-center text-center text-md-start">
            <div className="col-12 col-sm-6 col-lg-8">
              <h2 className="mb-3">{t("vision_title")}</h2>
              <h5>{t("vision")}</h5>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <img src={Content2Image} alt="content-4" className="commitment" />
            </div>
          </div>
        </section>
        <section className="container text-center commitment">
          <div className="row justify-content-center align-items-center text-center text-md-start">
            <div className="col-12 col-sm-6 col-lg-8">
              <h2 className="mb-3">{t("mission_title")}</h2>
              <h5>{t("mission")}</h5>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <img src={Content3Image} alt="content-4" className="commitment" />
            </div>
          </div>
        </section>
        <section className="container text-center commitment">
          <div className="row justify-content-center align-items-center text-center text-md-start">
            <div className="col-12 col-sm-6 col-lg-8">
              <h2 className="mb-3">{t("commitment_title")}</h2>
              <h5>
                {t("commitment_1")} <span>{t("commitment_2")}</span>{" "}
                {t("commitment_3")} <span>{t("commitment_4")}</span>
              </h5>
            </div>
            <div className="col-12 col-sm-6 col-lg-4">
              <img src={Content4Image} alt="content-4" className="commitment" />
            </div>
          </div>
        </section>
        <section className="container text-center team">
          <h2 className="mb-5">{t("meet_our_team")}</h2>
          <div>
            <img src={TeamsImage} style={{ width: '100%', height: 'auto' }} />
          </div>
        </section>
        <section className="text-center text-md-start value mb-0 pb-0">
          <div className="container">
            <h2 className="mb-2">{t("company_values_title")}</h2>
            <h5>{t("company_values")}</h5>
          </div>
          <img src={Content5Image} alt="content-5" className="footer" />
        </section>
      </main>
    </Layout>
  );
}

export default AboutUsPage;
