import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/service.png';

function ServicesDetail() {
    const { t } = useTranslation();

    return (
        <div className="container">
            <div className="grid items-center grid-cols-1 gap-3 md:grid-cols-10 py-24 md:max-w-[80%] mx-auto">
                <div className="col-span-1 md:col-span-6">
                    <div className="max-w-[80%] space-y-5 md:space-y-10">
                        <ServiceItem title={t('aps.serdet.1_title')} description={t('aps.serdet.1_description')} />
                        <ServiceItem title={t('aps.serdet.2_title')} description={t('aps.serdet.2_description')} />
                        <ServiceItem title={t('aps.serdet.3_title')} description={t('aps.serdet.3_description')} />
                        <ServiceItem title={t('aps.serdet.4_title')} description={t('aps.serdet.4_description')} />
                        <ServiceItem title={t('aps.serdet.5_title')} description={t('aps.serdet.5_description')} />
                    </div>
                </div>
                <div className="hidden md:block col-span-1 md:col-span-4 bg-blue-600">
                    <img src={BannerImage} className="size-full object-cover" />
                </div>
            </div>
        </div>
    );
}

function ServiceItem({ title, description }) {
    return (
        <div className="space-y-1 group">
            <h3 className="text-base md:text-xl">{title}</h3>
            <div className="h-1 md:h-2 bg-blue-900 transition-all duration-300 w-[20%] group-hover:w-[100%]"></div>
            <p className="font-light text-sm md:text-base">{description}</p>
        </div>
    );
}

export default ServicesDetail;