import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/jumbotron-partner.png';
import CompactJumbotron from '../../Components/CompactJumbotron';

function PartnerJumbotron() {
    const { t } = useTranslation();
    
    return (
        <CompactJumbotron
                backgroundImage={BannerImage}
                title={t('aps.partner_jb.title')}
                description={t('aps.partner_jb.description')} />
    );
}

export default PartnerJumbotron;