import { useTranslation } from 'react-i18next';
import HopesImage from '../../Assets/Image/Aps/hopes.png';

function Hopes() {
    const { t } = useTranslation();

    return (
        <div className="container py-20">
            <div className="grid md:grid-cols-2 gap-8 max-w-[80%] mx-auto">
                <div className="flex items-center">
                    <p className="font-light !leading-relaxed text-xl md:text-4xl">
                        {t('aps.hopes.description')}
                    </p>
                </div>
                <div>
                    <img src={HopesImage} />
                </div>
            </div>
        </div>
    );
}

export default Hopes;