import { Link } from 'react-router-dom';
import BannerImage from '../../Assets/Image/Aps/banner.png';
import { Trans, useTranslation } from 'react-i18next';

function HomeHero() {
    const { t } = useTranslation();

    return (
        <div id="home" className="h-screen relative bg-blue-500">
            <img src={BannerImage} className="size-full object-cover" />
            {/* <div className="absolute top-0 w-full h-full bg-blue-950 opacity-70" /> */}
            <div className="absolute left-0 w-full top-0 h-full">
                <div className="h-full text-white mx-auto p-4 container">
                    <div className="md:max-w-[60%] lg:max-w-[80%] h-full flex flex-col justify-center gap-4">
                        <h2 className="!leading-tight text-3xl md:text-6xl font-bold">
                            {t('aps.homehero.title')}
                        </h2>
                        <p className="text-base md:text-2xl font-light">
                            <Trans i18nKey="aps.homehero.description" components={{ highlight: <span className="text-green-400 text-base md:text-2xl font-bold" /> }} />
                        </p>
                        <div>
                            <Link to="/aps/about-us" className="outline outline-2 outline-white transition hover:bg-white hover:text-blue-800 px-4 py-2 text-sm md:text-base">
                                {t('aps.homehero.learn')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeHero;