import { useTranslation } from 'react-i18next';

function Mission() {
    const { t } = useTranslation();

    return (
        <div className="min-h-[300px] bg-blue-950 flex items-center">
            <p className="text-white container font-normal text-base md:text-lg !leading-relaxed text-center md:max-w-[40%]">
                {t('aps.mission.description')}
            </p>
        </div>
    );
}

export default Mission;