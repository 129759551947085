import LogoWhiteImage from "../../Assets/Image/logo-white.png";
import LogoLongImage from "../../Assets/Image/logo-long.png";
import WhatsappIcon from "../../Assets/Icon/whatsapp.png";
import InstagramIcon from "../../Assets/Icon/instagram.png";
import FacebookIcon from "../../Assets/Icon/facebook.png";
import LinkedinIcon from "../../Assets/Icon/linkedin-logo.png";
import { Link } from "react-router-dom";
import { t } from "i18next";

function RootFooter() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-md-end mb-5">
              <img src={LogoWhiteImage} alt="logo-white" />
            </div>
            <div className="col-lg-4 col-md-12 footer-links text-white text-center text-md-start mb-5">
              <h2>{t("contact_title")}</h2>
              <ul className="h-100">
                <li className="h4 mb-0 pb-0 ">
                  <div className="ms-auto me-auto ms-md-0 me-md-0 contact">
                    +62 21-270 982 44
                  </div>
                </li>
                <li className="h4 mt-0 pt-0 ">
                  <div className="ms-auto me-auto ms-md-0 me-md-0 contact">
                    info@thepasgroup.co.id
                  </div>
                </li>
                <li className="mt-auto pt-3">
                  <div className="social-link">
                    <a href="https://whatsapp.com/">
                      <img src={WhatsappIcon} alt="whatsapp" />
                    </a>
                    <a href="https://instagram.com/">
                      <img src={InstagramIcon} alt="instagram" />
                    </a>
                    <a href="https://facebook.com/">
                      <img src={FacebookIcon} alt="facebook" />
                    </a>
                    <a href="https://linkedin.com/">
                      <img src={LinkedinIcon} alt="linkedin" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-12 footer-links text-white">
              <h2>{t("address_title")}</h2>
              <div className="mt-2 mb-1">
                <div className="fw-bold">{t("head_office_jakarta")}</div>
                <div className="fw-normal">
                  Wisma Iskandarsyah Blok A4-A5, Jl. Iskandarsyah Raya
                  Kav.12-14, Melawai, Kebayoran Baru, Jakarta Selatan, DKI
                  Jakarta. 12160
                </div>
              </div>
              <div className="mb-1">
                <div className="fw-bold">{t("branch_office_cilegon")}</div>
                <div className="fw-normal">
                  Komplek Arga Baja Pura, Jl. Jalan Arga Raung Blok B3 No. 31,
                  Grogol, Gerogol, Cilegon, Banten. 42436
                </div>
              </div>
              <div className="mb-1">
                <div className="fw-bold">{t("branch_office_samarinda")}</div>
                <div className="fw-normal">
                  Jl. Haji Marhusin No.16, Sungai Kapih, Sambutan, Samarinda,
                  Kalimantan Timur. 75115
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 footer-links text-white second-footer">
              <div className="mb-1">
                <div className="fw-bold">{t("branch_office_balikpapan")}</div>
                <div className="fw-normal">
                  Jl. Syarifuddin Yoes No 60, Sepinggan, Balikpapan Selatan,
                  Balikpapan, Kalimantan Timur. 76115
                </div>
              </div>
              <div className="mb-1">
                <div className="fw-bold">{t("branch_office_bali")}</div>
                <div className="fw-normal">
                  Pelabuhan Benoa, Zona Marina, Pedungan, Denpasar Selatan, Kota
                  Denpasar, Bali. 80222
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default RootFooter;
