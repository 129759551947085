import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ServiceState from "../Services/state.js";
import HomePage from "../Pages/Home";
import AboutUsPage from "../Pages/AboutUs/index.js";
import OurServicesPage from "../Pages/OurServices/index.js";
import NewsPage from "../Pages/News/index.js";
import NewsDetailPage from "../Pages/News/detail.js";
import PartnersPage from "../Pages/Partners/index.js";
import CareersPage from "../Pages/Careers/index.js";
import ContactUsPage from "../Pages/ContactUs/index.js";
import PasniagaPage from "../Pages/Pasniaga/index.js";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../Locales/en/translation.json";
import translationID from "../Locales/id/translation.json";
import translationJP from "../Locales/jp/translation.json";
import translationCN from "../Locales/cn/translation.json";
import ApsPage from '../Pages/Aps/ApsPage.js';
import ApsAboutUsPage from '../Pages/Aps/ApsAboutUsPage.js';
import ApsServicePage from '../Pages/Aps/ApsServicePage.js';
import ApsPartnerPage from '../Pages/Aps/ApsPartnerPage.js';
import ApsContactUsPage from '../Pages/Aps/ApsContactUsPage.js';
import RootPage from '../Pages/Root/index.js';

const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  jp: {
    translation: translationJP,
  },
  cn: {
    translation: translationCN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const AppRoutes = () => {
  return (
    <ServiceState>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route exact path="/" element={<RootPage />} />
            <Route path="/psn" element={<HomePage />} />
            <Route path="/psn/about-us" element={<AboutUsPage />} />
            <Route path="/psn/our-services" element={<OurServicesPage />} />
            <Route path="/psn/news" element={<NewsPage />} />
            <Route path="/psn/news/:id" element={<NewsDetailPage />} />
            <Route path="/psn/partners" element={<PartnersPage />} />
            <Route path="/psn/careers" element={<CareersPage />} />
            <Route path="/psn/contact-us" element={<ContactUsPage />} />

            {/* Extended routes */}
            <Route path="/pasniaga" element={<PasniagaPage />} />
            <Route path="/aps" element={<ApsPage />} />
            <Route path="/aps/about-us" element={<ApsAboutUsPage />} />
            <Route path="/aps/service" element={<ApsServicePage />} />
            <Route path="/aps/partner" element={<ApsPartnerPage />} />
            <Route path="/aps/contact-us" element={<ApsContactUsPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </ServiceState>
  );
};

export default AppRoutes;
