import { useTranslation } from 'react-i18next';

function WhyChooseUs() {
    const { t } = useTranslation();

    return (
        <div className="container my-24">
            <h1 className="text-center text-3xl mb-4 !not-italic">{t('aps.whychoose.title')}</h1>
            <p className="text-center font-light italic md:max-w-[60%] mx-auto">
                “{t('aps.whychoose.description')}”
            </p>
        </div>
    );
}

export default WhyChooseUs;