import { useState } from "react";
import { Link } from "react-router-dom";
import LanguageIcon from "../../Assets/Icon/language.png";
import IndonesiaIcon from "../../Assets/Icon/indonesia.svg";
import EnglishIcon from "../../Assets/Icon/english.svg";
import JapanIcon from "../../Assets/Icon/japan.svg";
import ChinaIcon from "../../Assets/Icon/china.svg";
import LogoImage from "../../Assets/Image/logo.png";
import ListIcon from "../../Assets/Icon/list.svg";
import XIcon from "../../Assets/Icon/x.svg";
import { useTranslation } from "react-i18next";

function RootHeader(props) {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsmobile] = useState(false);
  const [languageIcon, setLanguageIcon] = useState(EnglishIcon);
  const pathname = window.location.pathname;

  const handleLanguageChange = (e, icon) => {
    i18n.changeLanguage(e);
    setLanguageIcon(icon);
  };

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <Link to="/" className="logo me-auto">
          <img src={LogoImage} alt="" className="img-fluid" />
        </Link>

        <div className="navbar">
          <div className="dropdown language">
            <div className="icon">
              <img
                src={LanguageIcon}
                alt="language"
                className="icon-selector"
              />
              <img
                src={languageIcon}
                alt="language-selected"
                className="icon-flag"
              />
            </div>
            <ul>
              <li>
                <img
                  src={IndonesiaIcon}
                  onClick={() => handleLanguageChange("id", IndonesiaIcon)}
                  alt="language-indonesia"
                />
              </li>
              <li>
                <img
                  src={EnglishIcon}
                  onClick={() => handleLanguageChange("en", EnglishIcon)}
                  alt="language-english"
                />
              </li>
              <li>
                <img
                  src={JapanIcon}
                  onClick={() => handleLanguageChange("jp", JapanIcon)}
                  alt="language-japan"
                />
              </li>
              <li>
                <img
                  src={ChinaIcon}
                  onClick={() => handleLanguageChange("cn", ChinaIcon)}
                  alt="language-china"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default RootHeader;
