function CompactJumbotron({ backgroundImage, title, description }) {
    return (
        <div className="h-[600px] relative">
            <img src={backgroundImage} className="size-full object-cover" />
            <div className="absolute top-0 left-0 size-full">
                <div className="container flex gap-3 flex-col justify-center items-center size-full">
                    <h2 className="text-white font-light text-3xl md:text-5xl">{title}</h2>
                    <p className="text-white font-light text-xl md:text-2xl text-center md:max-w-[70%]">
                        {description}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default CompactJumbotron;