import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Layout from "../../Layouts";
import AdminContext from "../../Services";
import ArrowRightIcon from "../../Assets/Icon/arrow-right-white.svg";
import NewsCard from "./Components/Card";
import { t } from "i18next";

function NewsDetailPage() {
  const { loading, getAllMaster } = useContext(AdminContext);
  const [data, setData] = useState({});
  const params = useParams();
  const navigate = useNavigate();

  const getData = async () => {
    const newData = await getAllMaster("public/news/" + params?.id);
    if (newData) {
      setData(newData);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    getData();
  }, [params?.id]);

  return (
    <Layout>
      <main id="main" className="about-us-detail">
        <section className="container">
          <img
            src={process.env.REACT_APP_FILE_URL + data?.photo_file}
            className="img-fluid rounded mb-4 img-news"
            alt=""
          />
          <h3>{data?.name}</h3>
          <span className="text-secondary">
            {moment(data?.created_at).format("dddd, DD MMMM YYYY")}
          </span>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data?.description }}
          ></div>
        </section>
        <section className="container">
          <h3 className="mb-4">{t("latest_news_title")}</h3>
          <div className="row align-items-center justify-content-center">
            {data?.latest?.map((item, index) => (
              <NewsCard item={item} key={index} />
            ))}
          </div>
          <div className="text-center mt-4">
            <button className="btn-all" onClick={()=>navigate("/news")}>
              <span className="me-3">{t("show_all_news_title")}</span>
              <img src={ArrowRightIcon} alt="" />
            </button>
          </div>
        </section>
      </main>
    </Layout>
  );
}

export default NewsDetailPage;
