import Layout from "../../Layouts";
import AboutUsImage from "../../Assets/Image/Home/about-us.png";
import AboutUsComponent from "../../Components/aboutUs";
import { useEffect } from "react";
import Partners from "../../Components/partners";
import { t } from "i18next";
import ProductShowcase from '../../Components/ProductShowCase';
import RootHeader from '../../Layouts/Components/RootHeader';
import RootFooter from '../../Layouts/Components/RootFooter';

function RootPage(params) {

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <RootHeader />
      <main id="main">
        <ProductShowcase />

        {/* <section className="container text-center">
          <h1 className="mb-3">{t("our_partners_title").toUpperCase()}</h1>
          <Partners />
        </section> */}
      </main>
      <RootFooter />
    </>
  );
}

export default RootPage;
