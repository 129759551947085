import Slider from "react-slick";
import OurService1Image from "../Assets/Image/Home/our-services-1.png";
import OurService2Image from "../Assets/Image/Home/our-services-2.png";
import OurService3Image from "../Assets/Image/Home/our-services-3.png";
import OurService4Image from "../Assets/Image/Home/our-services-4.png";
import OurService5Image from "../Assets/Image/Home/our-services-5.png";
import OurService6Image from "../Assets/Image/Home/our-services-6.png";
import OurService7Image from "../Assets/Image/Home/our-services-7.png";
import OurService8Image from "../Assets/Image/Home/our-services-8.png";
import BusinessAreaImage from "../Assets/Image/Home/peta.png";
import Gallery1Image from "../Assets/Image/Home/gallery1.jpg";
import Gallery2Image from "../Assets/Image/Home/gallery2.jpg";
import Gallery3Image from "../Assets/Image/Home/gallery3.jpg";
import Gallery4Image from "../Assets/Image/Home/gallery4.jpg";
import Gallery5Image from "../Assets/Image/Home/gallery5.jpg";
import Gallery6Image from "../Assets/Image/Home/gallery6.jpg";
import Gallery7Image from "../Assets/Image/Home/gallery7.jpg";
import Gallery8Image from "../Assets/Image/Home/gallery8.jpg";
import Gallery9Image from "../Assets/Image/Home/gallery9.jpg";
import Gallery10Image from "../Assets/Image/Home/gallery10.jpg";
import { t } from "i18next";

function AboutUsComponent(props) {
  const settings = {
    dots: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const gallery = [
    [
      {
        id: 1,
        image: Gallery1Image,
      },
      {
        id: 2,
        image: Gallery2Image,
      },
      {
        id: 3,
        image: Gallery3Image,
      },
      {
        id: 4,
        image: Gallery4Image,
      },
      {
        id: 5,
        image: Gallery5Image,
      },
    ],
    [
      {
        id: 6,
        image: Gallery6Image,
      },
      {
        id: 7,
        image: Gallery7Image,
      },
      {
        id: 8,
        image: Gallery8Image,
      },
      {
        id: 9,
        image: Gallery9Image,
      },
      {
        id: 10,
        image: Gallery10Image,
      },
    ],
  ];

  const ourServices = [
    {
      id: 1,
      title: t("services_1"),
      image: OurService1Image,
    },
    {
      id: 2,
      title: t("services_2"),
      image: OurService2Image,
    },
    {
      id: 3,
      title: t("services_3"),
      image: OurService3Image,
    },
    {
      id: 4,
      title: t("services_4"),
      image: OurService4Image,
    },
    {
      id: 5,
      title: t("services_5"),
      image: OurService5Image,
    },
    {
      id: 6,
      title: t("services_6"),
      image: OurService6Image,
    },
    {
      id: 7,
      title: t("services_7"),
      image: OurService7Image,
    },
    {
      id: 8,
      title: t("services_8"),
      image: OurService8Image,
    },
  ];
  return (
    <div>
      <section className="container text-center">
        {props?.showTitle && <h1 className="mb-3">{t("our_services_title").toUpperCase()}</h1>}
        <div>
          <Slider {...settings}>
            {ourServices?.map((item, index) => (
              <div key={index} className="card-service">
                <div className="card-body">
                  <img src={item?.image} alt="services" />
                  <div className="title">{item?.title}</div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className="container text-center">
        <h1 className="mb-3">{t("business_area_title")}</h1>
        <div>
          <img
            src={BusinessAreaImage}
            className="img-fluid"
            alt="business-area"
          />
        </div>
      </section>
      <section className="text-center">
        <div>
          <Slider {...settings} dots={false} speed={3000}>
            {gallery?.[0]?.map((item, index) => (
              <img
                src={item?.image}
                alt="services"
                className={`gallery type1-${index}`}
                key={index}
              />
            ))}
          </Slider>
          <Slider {...settings} rtl={true} dots={false} speed={3000}>
            {gallery?.[1]?.map((item, index) => (
              <img
                src={item?.image}
                alt="services"
                className={`gallery type2-${index}`}
                key={index}
              />
            ))}
          </Slider>
        </div>
      </section>
    </div>
  );
}

export default AboutUsComponent;
