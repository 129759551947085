import ScrollToTop from '../../Components/ScrollToTop';
import TailwindLoader from '../../Components/TailwindLoader';
import Footer from '../../Layouts/Aps/Footer';
import Header from '../../Layouts/Aps/Header';
import HomeHero from '../../Layouts/Aps/HomeHero';
import OurServices from '../../Layouts/Aps/OurServices';
import OurValues from '../../Layouts/Aps/OurValues';
import Partners from '../../Layouts/Aps/Partners';
import WhyChooseUs from '../../Layouts/Aps/WhyChooseUs';

function ApsPage() {
    return (
        <TailwindLoader>
            <Header active="xhomex" />
            <HomeHero />
            <OurValues />
            <OurServices />
            <WhyChooseUs />
            <Partners withHeader={true} />
            <Footer />

            <ScrollToTop />
        </TailwindLoader>
    );
}

export default ApsPage;