import { useTranslation } from 'react-i18next';
import BannerImage from '../../Assets/Image/Aps/banner.png';
import CompactJumbotron from '../../Components/CompactJumbotron';

function ContactUsJumbotron() {
    const { t } = useTranslation();

    return (
        <CompactJumbotron
                backgroundImage={BannerImage}
                title={t('aps.contactus_jb.title')}
                description={t('aps.contactus_jb.description')} />
    );
}

export default ContactUsJumbotron;