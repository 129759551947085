import { useTranslation } from 'react-i18next';
import Service1Image from '../../Assets/Image/Aps/service-1.png';
import Service2Image from '../../Assets/Image/Aps/service-2.png';
import Service3Image from '../../Assets/Image/Aps/service-3.png';
import Service4Image from '../../Assets/Image/Aps/service-4.png';
import Service5Image from '../../Assets/Image/Aps/service-5.png';

function OurServices() {
    const { t } = useTranslation();

    return (
        <div className="bg-blue-950 py-24">
            <div className="container">
                <h1 className="text-center !not-italic text-white text-3xl mb-5">Our Services</h1>

                <div className="mx-auto md:w-[600px] lg:w-[900px]">
                    <div className="grid grid-cols-1 md:grid-cols-3 ">
                        <ImageBox image={Service1Image} />
                        <TextBox title={t('aps.ourservices.item_1_title')} description={t('aps.ourservices.item_1_description')} />
                        <ImageBox image={Service2Image} />
                        <TextBox title={t('aps.ourservices.item_2_title')} description={t('aps.ourservices.item_2_description')} />
                        <ImageBox image={Service3Image} />
                        <TextBox title={t('aps.ourservices.item_3_title')} description={t('aps.ourservices.item_3_description')} />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 mt-5 md:!mt-0">
                        <FadeImageBox image={Service4Image} title={t('aps.ourservices.item_4_title')} description={t('aps.ourservices.item_4_description')} />
                        <FadeImageBox image={Service5Image} title={t('aps.ourservices.item_5_title')} description={t('aps.ourservices.item_5_description')} />
                    </div>
                </div>
            </div>
        </div>
    );
}

function FadeImageBox({ image, title, description }) {
    return (
        <div className="md:h-[200px] lg:h-[300px] relative">
            <img src={image} className="size-full object-cover" />
            <div className="absolute top-0 left-0 size-full flex flex-col justify-end p-4">
                <h3 className="font-semibold text-white text-base md:text-xl">{title}</h3>
                <p className=" font-light text-slate-300 text-base leading-5">
                    {description}
                </p>
            </div>
        </div>
    )
}

function ImageBox({ image }) {
    return (
        <div className="md:h-[200px] lg:h-[300px]">
            <img src={image} className="size-full object-cover" />
        </div>
    )
}

function TextBox({ title, description }) {
    return (
        <div className="md:h-[200px] lg:h-[300px] flex flex-col justify-center gap-1 bg-white p-4">
            <h3 className="text-blue-800 font-semibold">{title}</h3>
            <p className="font-light text-base">{description}</p>
        </div>
    );
}

export default OurServices;