import Team1Image from '../../Assets/Image/Aps/teams/aa.png';
import Team2Image from '../../Assets/Image/Aps/teams/mdp.png';
import Team3Image from '../../Assets/Image/Aps/teams/ntc.png';

function Teams() {
    return (
        <div className="min-h-[300px] bg-blue-950 flex items-center py-20">
            <div className="container">
                <h1 className="text-white text-center !not-italic text-3xl mb-2">Meet Our Team</h1>
                <p className="text-center text-slate-400 text-sm md:max-w-[65%] lg:max-w-[50%] mx-auto">
                    Our mission is to be the trusted partner in ship procurement, providing high-quality 
                    products and services that support the safety, efficiency, and sustainability of 
                    vessel operations
                </p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-3 md:max-w-[80%] lg:max-w-[65%] mx-auto mt-10 md:mt-16">
                    <ProfileItem photo={Team1Image} name="Alan Andhika" title="General Manager" />
                    <ProfileItem photo={Team2Image} name="Mohamad Denta Pradipa" title="Head of Sales & Marketing" />
                    <ProfileItem photo={Team3Image} name="Niken Talentia Cendikiasih" title="Finance & Accounting" />
                </div>
            </div>
        </div>
    );
}

function ProfileItem({ photo, name, title }) {
    return (
        <div className="flex flex-col items-center">
            <div className="w-[150px] h-[150px] bg-black rounded-full mb-2">
                <img src={photo} className="size-full object-cover" />
            </div>
            <h4 className="text-xl font-semibold text-center text-white">{name}</h4>
            <span className="text-base text-slate-300">{title}</span>
        </div>
    );
}

export default Teams;